document.addEventListener("DOMContentLoaded", function () {
	// Helper function to get URL parameters
	function getUrlParameter(name) {
		name = name.replace(/[\[\]]/g, '\\$&');
		const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
		const results = regex.exec(window.location.href);
		if (!results) return undefined;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	// Helper function to get cookies
	function getCookie(name) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(';').shift();
		return undefined;
	}

	// Helper function to set cookies
	function setCookie(name, value, days = 30) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Default to 30 days
		const expires = `expires=${date.toUTCString()}`;
		document.cookie = `${name}=${encodeURIComponent(value)}; ${expires}; path=/; SameSite=Lax`;
	}

	// Define UTM variables, setting them to undefined if not found in URL or cookies
	const utmSource = getUrlParameter("utm_source") || getCookie("utm_source") || undefined;
	const utmCampaign = getUrlParameter("utm_campaign") || getCookie("utm_campaign") || undefined;
	const utmMedium = getUrlParameter("utm_medium") || getCookie("utm_medium") || undefined;
	const utmTerm = getUrlParameter("utm_term") || getCookie("utm_term") || undefined;
	const googleClickId = getUrlParameter("gclid") || getCookie("gclid") || undefined;
	const facebookClickId = getUrlParameter("fbclid") || getCookie("fbclid") || undefined;

	// Set cookies if UTM parameters or click IDs exist
	if (utmSource !== undefined) setCookie("utm_source", utmSource);
	if (utmCampaign !== undefined) setCookie("utm_campaign", utmCampaign);
	if (utmMedium !== undefined) setCookie("utm_medium", utmMedium);
	if (utmTerm !== undefined) setCookie("utm_term", utmTerm);
	if (googleClickId !== undefined) setCookie("gclid", googleClickId);
	if (facebookClickId !== undefined) setCookie("fbclid", facebookClickId);

	// Check if the form fields exist before setting their values
	const sourceField = document.querySelector("input[name='field[199]']");
	const mediumField = document.querySelector("input[name='field[200]']");
	const campaignField = document.querySelector("input[name='field[201]']");
	const termField = document.querySelector("input[name='field[203]']");
	const gclidField = document.querySelector("input[name='field[202]']");
	const fbclidField = document.querySelector("input[name='field[204]']");

	// Populate form fields only if the values are defined
	if (utmSource !== undefined && sourceField) sourceField.value = utmSource;
	if (utmMedium !== undefined && mediumField) mediumField.value = utmMedium;
	if (utmCampaign !== undefined && campaignField) campaignField.value = utmCampaign;
	if (utmTerm !== undefined && termField) termField.value = utmTerm;
	if (googleClickId !== undefined && gclidField) gclidField.value = googleClickId;
	if (facebookClickId !== undefined && fbclidField) fbclidField.value = facebookClickId;
});
